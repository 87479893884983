<template>
	<div class="add-swiper-page">
		<div class="page-title mb20">{{ lun_id ? '添加轮播图' : '轮播图编辑' }}</div>
		<a-form-model>
			<a-form-model-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="标题:">
				<a-input v-model="swiper.title" />
			</a-form-model-item>
			<a-form-model-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="页面链接:">
				<a-cascader
					v-if="loaded"
					:field-names="{ label: 'value', value: 'page_id', children: 'children' }"
					:load-data="getLink"
					change-on-select
					:options="linkClass"
					placeholder="请先选择分类"
					:default-value="pageId"
					@change="linkChange"
				/>
			</a-form-model-item>
			<a-form-model-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="排序:" >
				<a-input v-model="swiper.sort" />
			</a-form-model-item>
			<a-form-model-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="图片:">
				<upload v-if="showUp" :img_id="swiper.img_id" @upImgId="upImgId"/>
			</a-form-model-item>
		</a-form-model>
		<div class="control-box flex flex-end">
			<a-button type="primary" @click="submitSwiper">提交</a-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			linkClass: [],
			lun_id: false,
			imgModalShow: false,
			APP_ID: '',
			APP_SECRET: '',
			swiper: {
				type: 1,
				title: '',
				page_id: '',
				param: '',
				sort: '',
				img_id: '',
				is_show: ''
			},
			file: null,
			previewVisible: false,
			preview: false,
			previewImage: '',
			loaded: false,
			showUp: false,
			init: true
		};
	},
	computed: {
		pageId() {
			if (this.swiper.page_id == '') {
				return [];
			} else {
				return [parseFloat(this.swiper.page_id), parseFloat(this.swiper.param)];
			}
		}
	},
	watch:{},
	created() {},
	mounted() {
		if (this.$route.query.lun_id) {
			this.lun_id = this.$route.query.lun_id;
			this.getSwiper();
		}else{
			this.showUp = true
			this.loaded = true
			this.getInitLink();
		}
	},
	methods: {
		getSwiper() {
			let t = this;
			t.$get({
				port: 'a',
				url: 'lunboInfo',
				data: { lun_id: t.lun_id }
			})
			.then(res => {
				let { code, data, msg } = res;
				if (code == 0) {
					t.showUp = true
					t.swiper.title = data.title;
					t.swiper.page_id = data.page_id;
					t.swiper.param = data.param;
					t.swiper.sort = data.sort;
					t.swiper.img_id = data.img_id;
					t.swiper.is_show = data.is_show;
					t.getInitLink()
				} else {
					t.$message.error(msg, 1.5);
				}
			})
			.catch(err => {});
		},
		getInitLink() {
			let t = this;
			t.$get({
				port: 'a',
				url: 'pageList',
				data: {}
			}).then(res => {
				let { code, data, msg } = res;
				if (code == 0) {
					for (let i = 0; i < data.length; i++) {
						this.linkClass.push({ ...data[i], isLeaf: false });
					}
					if(t.lun_id && t.init) {
						for(let k = 0; k < t.linkClass.length; k++) {
							console.log(t.linkClass[k].id, t.pageId[0])
							if(t.linkClass[k].id == t.pageId[0]) {
								console.log('t.getLink')
								t.getLink([t.linkClass[k]])
								break
							}
						}
					}
					
					t.loaded = true
				} else {
					t.$message.error(msg, 1.5);
				}
			})
			.catch(err => {});
		},
		getLink(selectedOptions) {
			console.log(13221312312);
			console.log(selectedOptions);
			const targetOption = selectedOptions[selectedOptions.length - 1];
			
			targetOption.loading = true;
			console.log('zoudao zhe ?');
			let t = this;
			t.$get({
				port: 'a',
				url: 'paramList',
				data: { page_id: targetOption.page_id }
			})
			.then(res => {
				let { code, data, msg } = res;
				t.loaded = true
				if (code == 0) {
					t.init = false
					let sub = [];
					for (let i = 0; i < data.length; i++) {
						sub.push({
							value: data[i].name,
							page_id: data[i].param,
							isLeaf: true
						});
					}
					targetOption.children = sub;
					this.linkClass = [...this.linkClass];
					targetOption.loading = false;
				} else {
					
					t.$message.error(msg, 1.5);
				}
			})
			.catch(err => {
				console.log('cuou')
			});
		},
		linkChange(e) {
			console.log(e);
			this.swiper.page_id = e[0];
			this.swiper.param = e[1];
		},
		submitSwiper() {
			let t = this,
				url = '',
				data = {};
			// 通过判断有没有lunbo图的id来确定是修改还是新增
			if (t.lun_id) {
				url = 'editLunbo';
				data = {
					...this.swiper,
					lun_id: this.lun_id
				};
			} else {
				(url = 'createLunbo'), (data = { ...this.swiper });
			}
			t.$post({
				port: 'a',
				url: url,
				data: data
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						if (t.lun_id) {
							t.getSwiper()
						}else{
							t.$router.go(-1)
						}
						this.swiper = {
							title: '',
							sort: '',
							img_id: '',
							is_show: '',
							type: 1
						};
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {});
		},
		upImgId(idList) {
			// this.album.content = id.join(',')
			console.log(idList)
			let ids = []
			for (let i = 0; i < idList.length; i++) {
				let item = idList[i]
				if(item.response) {
					if(item.response.code == 0) {
						ids.push(item.response.data.img_id)
					}
				}else{
					ids.push(item.img_id)
				}
			}
			this.swiper.img_id = ids.join(',')
		}
	}
};
</script>

<style lang="less">
.img-box {
	width: 104px;
	height: 104px;
	position: relative;
	margin-right: 8px;
	margin-bottom: 8px;
	&::after {
		content: '';
		left: 0;
		top: 0;
		border: 1px solid #ddd;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 0;
		pointer-events: none;
	}
	.del {
		position: absolute;
		right: -10px;
		top: -10px;
		background-color: rgba(0, 0, 0, 0.8);
		color: #f13636;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		z-index: 20;
	}
	img {
		object-fit: contain;
		width: 100%;
		height: 100%;
		display: block;
	}
}
</style>
